import React, { useEffect } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import { AppStatusBar } from '../../components';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('ConfigsPage.title')}
          buttonStack={<AppStatusBar type="toggle" />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <></>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
