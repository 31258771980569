import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import {
  Page,
  Layout,
  DataTable,
  DataList,
  FormField,
} from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Select,
  Tag,
  Text,
  Textarea,
  Thumbnail,
  Title,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import { useHistory, useParams } from 'react-router-dom';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  CreditCardIcon,
  MailIcon,
  MoneyIcon,
} from '@nimbus-ds/icons';
import { formatDate, formatMoney } from '../../utils/formatUtils';

interface INewInfo {
  address_id: number;
  shipping_price: number;
  extra_info: string;
  payment_status: 'paid' | 'pending';
}

const emptyNewInfo: INewInfo = {
  address_id: -1,
  shipping_price: 0,
  extra_info: '',
  payment_status: 'pending',
};

const GroupPage: React.FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const pageSize = 100;

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSendNewOrder, setLoadingSendNewOrder] =
    useState<boolean>(false);
  const [showGroupOrders, setShowGroupOrders] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>('');
  const [rows, setRows] = useState<any[]>([]);
  const [newInfo, setNewInfo] = useState<INewInfo>({ ...emptyNewInfo });
  const [listOrderById, setListOrderById] = useState<any>({});
  const [checkedRows, setCheckedRows] = useState<number[]>([]);
  const [headerCheckboxStatus, setHeaderCheckboxStatus] = useState(false);
  const [previewNewOrder, setPreviewNewOrder] = useState<{
    [key: string]: any;
  }>({});
  const [headerIndeterminateStatus, setHeaderIndeterminateStatus] =
    useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<
    'ascending' | 'descending'
  >('descending');
  const [sortColumn, setSortColumn] = useState<'id' | 'clientName'>('id');

  const getOrders = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/customers/${id}/orders`);
      if (result.data !== null) {
        const resOrders = result.data;

        setRows(resOrders);

        const tempOrdersById: { [key: string]: any } = {};
        for (let x = 0; x < resOrders.length; x++) {
          tempOrdersById[resOrders[x].id] = resOrders[x];
        }

        setListOrderById({ ...tempOrdersById });

        if (resOrders.length) {
          setClientName(resOrders[0].customer.name);
        }
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('OrdersPage.Toasts.get_orders_error')}`,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    if (id && id.length) {
      getOrders();
    }
  }, []);

  useEffect(() => {
    if (checkedRows.length === rows.length) {
      setHeaderCheckboxStatus(true);
      setHeaderIndeterminateStatus(false);
    } else if (checkedRows.length > 0) {
      setHeaderCheckboxStatus(false);
      setHeaderIndeterminateStatus(true);
    } else {
      setHeaderCheckboxStatus(false);
      setHeaderIndeterminateStatus(false);
    }

    generatePreviewNewOrder();
  }, [checkedRows.length, rows.length]);

  const handleChangeNewInfo = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...newInfo };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setNewInfo({ ...helpConfig });
    return false;
  };

  const handleSendNewOrder = async () => {
    setLoadingSendNewOrder(true);
    try {
      const payload: { [key: string]: any } = {
        orders_id: checkedRows,
        extra_info: newInfo.extra_info,
        address_id: newInfo.address_id,
        payment_status: newInfo.payment_status,
        shipping_price: newInfo.shipping_price,
      };

      const result = await apiInstance.post(`/orders/group`, payload);
      if (result.status === 201) {
        setTimeout(function () {
          push(`/?reset=${new Date().toISOString()}`);
        }, 2000);
        addToast({
          id: 'success_get' + new Date().toISOString(),
          type: 'success',
          text: `${t('OrdersPage.Toasts.post_new_orders_success')}`,
        });
      } else {
        addToast({
          id: 'error_get' + new Date().toISOString(),
          type: 'danger',
          text: `${result.data.error}`,
        });
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('OrdersPage.Toasts.post_new_orders_error')}`,
      });
    }
    setLoadingSendNewOrder(false);
  };

  const getDefaultAddress = (customer: { [key: string]: any }) => {
    let default_customer: { [key: string]: any } | null = null;
    if (customer.default_address) {
      default_customer = customer.default_address;
    } else {
      for (let x = 0; x < customer.addresses.length; x++) {
        const address = customer.addresses[x];
        if (address.default) {
          default_customer = address;
          break;
        }
      }

      if (default_customer === null) {
        default_customer = customer.addresses[0];
      }
    }
    return default_customer;
  };

  const generatePreviewNewOrder = () => {
    const newOrder: { [key: string]: any } = {
      subtotal: 0,
      discount: 0,
      shipping: 0,
      total: 0,
      payment_by_order: [],
      shipping_by_order: [],
      customers: [],
    };
    const products: any[] = [];

    for (let x = 0; x < checkedRows.length; x++) {
      const order = listOrderById[checkedRows[x]];

      console.log(order);

      newOrder.subtotal += parseFloat(order.subtotal || '0');
      newOrder.discount += parseFloat(order.discount || '0');
      newOrder.total += parseFloat(order.total || '0');
      newOrder.shipping += parseFloat(order.shipping_cost_customer || '0');

      newOrder.customers.push(order.customer);
      newOrder.payment_by_order.push({
        status: order.payment_status,
        details: order.payment_details,
      });
      newOrder.shipping_by_order.push({
        shipping_option: order.shipping_option,
        shipping_address: order.shipping_address,
        shipping_cost_customer: order.shipping_cost_customer,
        shipping_cost_owner: order.shipping_cost_owner,
        shipping_status: order.shipping_status,
      });

      for (const product of order.products) {
        products.push(product);
      }
    }
    newOrder['products'] = products;

    console.log(`newOrder===>`, newOrder);

    const default_customer = newOrder.customers.length
      ? getDefaultAddress(newOrder.customers[0])
      : null;
    const default_address_id =
      default_customer === null ? -1 : default_customer.id;
    // if (newOrder.customers.length && newOrder.customers[0].default_address) {
    //   default_address_id = newOrder.customers[0].default_address.id;
    // } else {
    //   for (let x = 0; x < newOrder.customers[0].addresses.length; x++) {
    //     let address = newOrder.customers[0].addresses[x];
    //     if (address.default) {
    //       default_address_id = address.id;
    //       break;
    //     }
    //   }

    //   if (default_address_id < 1) {
    //     default_address_id = newOrder.customers[0].addresses[0].id;
    //   }
    // }

    setNewInfo({
      address_id: default_address_id,
      shipping_price: newOrder.shipping,
      extra_info: '',
      payment_status: 'pending',
    });

    setPreviewNewOrder({ ...newOrder });
  };

  const handleRowClick = (id: number) => {
    if (checkedRows.includes(id)) {
      setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
    } else {
      setCheckedRows([...checkedRows, id]);
    }
  };

  const handleHeaderCheckboxClick = () => {
    if (headerCheckboxStatus) {
      setCheckedRows([]);
    } else {
      const rowIds = rows.map((row) => row.id);
      setCheckedRows(rowIds);
    }
  };

  const handlePageChange = (page: number): void => {
    setCurrentPage(page);
  };

  const handleSort = (column: 'id' | 'clientName') => {
    if (column === sortColumn) {
      setSortDirection(
        sortDirection === 'ascending' ? 'descending' : 'ascending',
      );
    } else {
      setSortColumn(column);
      setSortDirection('ascending');
    }
  };

  const sortCompareFunction = (rowA: any, rowB: any) => {
    if (sortColumn === 'id') {
      return sortDirection === 'ascending'
        ? rowA.id - rowB.id
        : rowB.id - rowA.id;
    }
    if (sortColumn === 'clientName') {
      return sortDirection === 'ascending'
        ? rowA.clientName.localeCompare(rowB.clientName)
        : rowB.clientName.localeCompare(rowA.clientName);
    }
    return 0;
  };

  const getDisplayedRows = (): any[] => {
    const sortedRows = rows.slice().sort(sortCompareFunction);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return sortedRows.slice(startIndex, endIndex);
  };

  const displayedRows = getDisplayedRows();
  const totalRows = rows.length;
  const firstRow = (currentPage - 1) * pageSize + 1;
  const lastRow = Math.min(currentPage * pageSize, totalRows);

  const tableHeader = (
    <DataTable.Header
      checkbox={{
        name: 'check-all-rows',
        checked: headerCheckboxStatus,
        onChange: handleHeaderCheckboxClick,
        indeterminate: headerIndeterminateStatus,
      }}
    >
      <DataTable.Cell width="120px">
        <Box display="flex" gap="2" alignItems="center">
          {t('OrdersPage.Orders.Table.Head.title1')}
          <IconButton
            source={
              sortDirection === 'ascending' ? (
                <ChevronUpIcon size={10} />
              ) : (
                <ChevronDownIcon size={10} />
              )
            }
            size="1rem"
            onClick={() => handleSort('id')}
          />
        </Box>
      </DataTable.Cell>
      <DataTable.Cell>
        {t('OrdersPage.Orders.Table.Head.title2')}
      </DataTable.Cell>
      <DataTable.Cell width="150px">
        {t('OrdersPage.Orders.Table.Head.title3')}
      </DataTable.Cell>
      <DataTable.Cell width="100px">
        {t('OrdersPage.Orders.Table.Head.title4')}
      </DataTable.Cell>
      <DataTable.Cell width="100px">
        {t('OrdersPage.Orders.Table.Head.title5')}
      </DataTable.Cell>
      <DataTable.Cell width="100px">
        {t('OrdersPage.Orders.Table.Head.title6')}
      </DataTable.Cell>
      <DataTable.Cell width="100px">
        {t('OrdersPage.Orders.Table.Head.title7')}
      </DataTable.Cell>
    </DataTable.Header>
  );

  const tableFooter = (
    <DataTable.Footer
      itemCount={`${t(
        'OrdersPage.Orders.Table.Footer.text1',
      )} ${firstRow}-${lastRow} ${t(
        'OrdersPage.Orders.Table.Footer.text2',
      )} ${totalRows}`}
      pagination={{
        pageCount: Math.ceil(totalRows / pageSize),
        activePage: currentPage,
        onPageChange: handlePageChange,
      }}
    />
  );

  const hasBulkActions = checkedRows.length > 0 && (
    <DataTable.BulkActions
      checkbox={{
        name: 'check-all',
        checked: headerCheckboxStatus,
        onChange: handleHeaderCheckboxClick,
        indeterminate: headerIndeterminateStatus,
      }}
      label={`${checkedRows.length} ${t(
        'OrdersPage.Orders.Table.Head.selected',
      )}`}
      action={
        <Box display="flex" gap="1">
          <Button
            appearance="primary"
            onClick={() => setShowGroupOrders(true)}
            disabled={checkedRows.length < 2}
          >
            {t('OrdersPage.Orders.Table.Head.group_cta')}
          </Button>
        </Box>
      }
    />
  );

  const PreviewProducts = () => {
    return (
      <>
        {previewNewOrder.products.map((product: any, i: number) => {
          return (
            <DataList.Row key={i} gap="2" topDivider={false} paddingY="2">
              <Box display="flex" justifyContent="space-between" gap="2">
                <Thumbnail
                  alt={product.name}
                  width="104px"
                  src={product.image ? product.image.src || null : null}
                />
                <Box width="100%">
                  <Box display="grid" gap="1">
                    <Text fontWeight="bold" fontSize="highlight" as="span">
                      {product.name_without_variants !== null
                        ? product.name_without_variants
                        : product.name}
                    </Text>
                    {product.variant_values &&
                      product.variant_values.length > 0 && (
                        <Text as="span" fontSize="caption">
                          {product.variant_values.join(', ')}
                        </Text>
                      )}
                    {Array.isArray(product.properties) &&
                    product.properties.length > 0
                      ? product.properties.map(
                          (property: any, index: number) => (
                            <Text key={index} as="span" fontSize="caption">
                              {`${property.key}: ${property.value}`}
                            </Text>
                          ),
                        )
                      : product.properties &&
                        typeof product.properties === 'object'
                      ? Object.entries(product.properties).map(
                          ([key, value]) => (
                            <Text key={key} as="span" fontSize="caption">
                              {`${key}: ${value}`}
                            </Text>
                          ),
                        )
                      : null}
                    <Text fontWeight="bold">
                      {product.quantity}x {formatMoney(product.price)}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text fontSize="highlight" fontWeight="bold">
                    {formatMoney(product.quantity * +product.price)}
                  </Text>
                </Box>
              </Box>
            </DataList.Row>
          );
        })}
      </>
    );
  };

  const PreviewProductsTotals = () => (
    <>
      <DataList.Row
        gap="2"
        paddingY="2"
        topDivider={false}
        backgroundColor="neutral-surface"
      >
        <Box justifyContent="space-between" display="flex">
          <Text fontWeight="medium">
            Subtotal ({previewNewOrder.products.lenght} produtos):
          </Text>
          <Text fontWeight="medium">
            {formatMoney(previewNewOrder.subtotal)}
          </Text>
        </Box>
      </DataList.Row>
      {previewNewOrder.discount > 0 ? (
        <DataList.Row gap="2" paddingY="2" topDivider={false}>
          <Box justifyContent="space-between" display="flex">
            <Text fontWeight="medium">Descontos:</Text>
            <Text fontWeight="medium" color="danger-textLow">
              -{formatMoney(previewNewOrder.discount)}
            </Text>
          </Box>
        </DataList.Row>
      ) : (
        <></>
      )}
      <DataList.Row gap="2" paddingY="2" topDivider={false}>
        <Box justifyContent="space-between" display="flex">
          <Text fontWeight="medium">Entrega:</Text>
          <Text fontWeight="medium">
            {formatMoney(previewNewOrder.shipping)}
          </Text>
        </Box>
      </DataList.Row>
      <DataList.Row
        gap="2"
        paddingY="2"
        topDivider={false}
        backgroundColor="neutral-surface"
      >
        <Box justifyContent="space-between" display="flex">
          <Text fontWeight="bold" fontSize="highlight">
            Total:
          </Text>
          <Text fontWeight="bold" fontSize="highlight">
            {formatMoney(previewNewOrder.total)}
          </Text>
        </Box>
      </DataList.Row>
    </>
  );

  const PreviewDefaultCustomerAdrress = () => {
    if (previewNewOrder.customers.length) {
      const default_customer_address = getDefaultAddress(
        previewNewOrder.customers[0],
      );
      if (default_customer_address === null) {
        return (
          <Text fontWeight="bold" fontSize="highlight">
            Endereço não encontrado
          </Text>
        );
      }
      return (
        <>
          <Text fontWeight="bold" fontSize="highlight">
            {default_customer_address.name || '---'}
          </Text>
          <Box marginTop="1">
            <Text fontSize="caption">Endereço</Text>
            <Text>
              {`${default_customer_address.address || '---'} (${
                default_customer_address.zipcode || '---'
              })`}
            </Text>
            <Text fontSize="caption">
              {`${default_customer_address.locality || '---'}, ${
                default_customer_address.city || '---'
              }, ${default_customer_address.province || '---'}, ${
                default_customer_address.country || '---'
              }`}
            </Text>
          </Box>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Page>
        <Page.Header
          title={
            loading
              ? `${t(`OrdersPage.loadingTitle`)}`
              : `${t(`OrdersPage.title`)} ${clientName}`
          }
          subtitle={
            showGroupOrders
              ? `${t('OrdersPage.NewOrderPreview.subtitle')}`
              : `${t('OrdersPage.subtitle')}`
          }
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              {showGroupOrders ? (
                <Box display="grid" gap="4">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="4"
                  >
                    <Text>
                      {t('OrdersPage.NewOrderPreview.qty_orders_to_group', {
                        qty: checkedRows.length,
                      })}
                    </Text>
                    <Button
                      onClick={() => setShowGroupOrders(false)}
                      appearance="neutral"
                    >
                      <Icon source={<ChevronLeftIcon />} color="currentColor" />
                      {t('OrdersPage.NewOrderPreview.cta_back_to_orders')}
                    </Button>
                  </Box>

                  <Layout columns="2 - asymmetric">
                    <Layout.Section>
                      <Box display="grid" gap="6">
                        <Title as="h2">
                          {t('OrdersPage.NewOrderPreview.Section1.title')}
                        </Title>
                        <Card padding="none">
                          <Card.Header
                            padding="base"
                            title={`${previewNewOrder.products.length} ${t(
                              'OrdersPage.NewOrderPreview.Section1.Products.title',
                            )}`}
                          />
                          {previewNewOrder && previewNewOrder.products ? (
                            <DataList bottomDivider={false}>
                              <PreviewProducts />
                              <PreviewProductsTotals />
                            </DataList>
                          ) : (
                            <></>
                          )}
                        </Card>
                        <Card>
                          <Card.Header
                            title={`${t(
                              'OrdersPage.NewOrderPreview.Section1.Payment.title',
                            )}`}
                          />
                          <Card.Body>
                            {previewNewOrder.payment_by_order.length ? (
                              <Box display="grid" gap="2">
                                {previewNewOrder.payment_by_order.map(
                                  (payment: any, i: number) => (
                                    <Box display="flex" gap="2" key={i}>
                                      <Text fontWeight="bold">{`Pedido ${
                                        i + 1
                                      }:`}</Text>
                                      <Tag>{payment.details.method}</Tag>
                                      <Tag appearance="warning">
                                        <CreditCardIcon size={12} />
                                        <Text color="warning-textLow">
                                          {t(
                                            `OrdersPage.NewOrderPreview.Section1.Payment.status.${payment.status}`,
                                          )}
                                        </Text>
                                      </Tag>
                                    </Box>
                                  ),
                                )}
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header
                            title={`${t(
                              'OrdersPage.NewOrderPreview.Section1.Shipping.title',
                            )}`}
                          />
                          <Card.Body>
                            {previewNewOrder.payment_by_order.length ? (
                              <Box display="grid" gap="2">
                                {previewNewOrder.shipping_by_order.map(
                                  (shipping: any, i: number) => (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      gap="2"
                                      key={i}
                                    >
                                      <Box display="flex" gap="2" key={i}>
                                        <Text fontWeight="bold">{`Pedido ${
                                          i + 1
                                        }:`}</Text>
                                        <Text>{shipping.shipping_option}</Text>
                                      </Box>
                                      <Text fontWeight="bold">
                                        {formatMoney(
                                          shipping.shipping_cost_customer,
                                        )}
                                      </Text>
                                    </Box>
                                  ),
                                )}
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Card.Body>
                        </Card>
                      </Box>
                    </Layout.Section>
                    <Layout.Section title="Detalhes do pedido">
                      <Box display="grid" gap="6">
                        <Title as="h2">
                          {t('OrdersPage.NewOrderPreview.Section2.title')}
                        </Title>

                        <Card>
                          <Card.Header
                            title={`${t(
                              'OrdersPage.NewOrderPreview.Section2.Customer.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="2">
                              <Text fontWeight="bold" fontSize="highlight">
                                {previewNewOrder.customers[0].name}
                              </Text>
                              <Box display="flex" gap="2" alignItems="center">
                                <Box as="span">
                                  <Icon source={<MailIcon />} />
                                </Box>
                                <Text>
                                  {previewNewOrder.customers[0].email}
                                </Text>
                              </Box>
                              <Box marginTop="1">
                                <Text fontSize="caption">CPF/CNPJ</Text>
                                <Text>
                                  {previewNewOrder.customers[0].identification}
                                </Text>
                              </Box>
                            </Box>
                          </Card.Body>
                        </Card>

                        <Card>
                          <Card.Header
                            title={`${t(
                              'OrdersPage.NewOrderPreview.Section2.CustomerAddress.title',
                            )}`}
                          />
                          <Card.Body>
                            <Box display="grid" gap="2">
                              <PreviewDefaultCustomerAdrress />
                            </Box>
                          </Card.Body>
                        </Card>

                        <Card>
                          <Card.Header title={'Dados para agrupamento'} />
                          <Card.Body>
                            <Box width="100%" display="grid" gap="2">
                              <FormField label={`Endereço de entrega`}>
                                <Select
                                  disabled={loading}
                                  id="address_id"
                                  name="address_id"
                                  onChange={handleChangeNewInfo}
                                  value={newInfo.address_id}
                                >
                                  {previewNewOrder.customers[0].addresses.map(
                                    (address: any, i: number) => {
                                      if (address.number !== null) {
                                        return (
                                          <Select.Option
                                            key={i}
                                            value={address.id}
                                            label={`${address.name} - ${address.address}, ${address.city}, ${address.province}`}
                                          />
                                        );
                                      } else {
                                        return <></>;
                                      }
                                    },
                                  )}
                                </Select>
                              </FormField>
                              <FormField
                                label={`Valor do frete para novo pedido`}
                              >
                                <Input
                                  append={
                                    <Icon
                                      source={<Icon source={<MoneyIcon />} />}
                                    />
                                  }
                                  appendPosition="start"
                                  type="number"
                                  disabled={loading}
                                  id="shipping_price"
                                  name="shipping_price"
                                  onChange={handleChangeNewInfo}
                                  value={newInfo.shipping_price}
                                />
                              </FormField>
                              <FormField label={`Informações extras`}>
                                <Textarea
                                  disabled={loading}
                                  id="extra_info"
                                  name="extra_info"
                                  onChange={handleChangeNewInfo}
                                  value={newInfo.extra_info}
                                />
                              </FormField>
                              <FormField label={`Status do pagamento`}>
                                <Select
                                  disabled={loading}
                                  id="payment_status"
                                  name="payment_status"
                                  onChange={handleChangeNewInfo}
                                  value={newInfo.payment_status}
                                >
                                  <Select.Option
                                    value={'pending'}
                                    label={`Pagamento pendente`}
                                  />
                                  <Select.Option
                                    value={'paid'}
                                    label={`Pagamento realizado`}
                                  />
                                </Select>
                              </FormField>
                              <Button
                                appearance="primary"
                                disabled={loadingSendNewOrder}
                                onClick={handleSendNewOrder}
                              >
                                Unificar pedidos
                              </Button>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Box>
                    </Layout.Section>
                  </Layout>
                </Box>
              ) : (
                <DataTable
                  header={tableHeader}
                  footer={tableFooter}
                  bulkActions={hasBulkActions}
                >
                  {loading ? (
                    <DataTable.Row
                      key={1}
                      checkbox={{
                        name: `check-${1}`,
                        checked: checkedRows.includes(1),
                        onChange: () => {
                          console.log('');
                        },
                      }}
                    >
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <Text.Skeleton />
                      </DataTable.Cell>
                    </DataTable.Row>
                  ) : (
                    displayedRows.map((row) => (
                      <DataTable.Row
                        key={row.id}
                        backgroundColor={
                          checkedRows.includes(row.id)
                            ? {
                                rest: 'primary-surface',
                                hover: 'primary-surfaceHighlight',
                              }
                            : {
                                rest: 'neutral-background',
                                hover: 'neutral-surface',
                              }
                        }
                        checkbox={{
                          name: `check-${row.id}`,
                          checked: checkedRows.includes(row.id),
                          onChange: () => handleRowClick(row.id),
                        }}
                      >
                        <DataTable.Cell>#{row.number}</DataTable.Cell>
                        <DataTable.Cell>{row.customer.name}</DataTable.Cell>
                        <DataTable.Cell>
                          {formatDate(row.created_at)}
                        </DataTable.Cell>
                        <DataTable.Cell>{row.products.length}</DataTable.Cell>
                        <DataTable.Cell>
                          {formatMoney(row.subtotal)}
                        </DataTable.Cell>
                        <DataTable.Cell>
                          {formatMoney(row.discount)}
                        </DataTable.Cell>
                        <DataTable.Cell>
                          {formatMoney(row.total)}
                        </DataTable.Cell>
                      </DataTable.Row>
                    ))
                  )}
                </DataTable>
              )}
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default GroupPage;
