import { ChangeEvent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Icon,
  Input,
  Link,
  Pagination,
  Table,
  Text,
  useToast,
} from '@nimbus-ds/components';
import { EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces';
import { RedoIcon, SearchIcon } from '@nimbus-ds/icons';
import { useHistory } from 'react-router-dom';
import { BillingButton } from '../../components';

function PageMain() {
  const { t } = useTranslation();

  const { push } = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [customersMeta, setCustomersMeta] = useState<IPaginateMeta>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [haveSearchQuery, setHaveSearchQuery] = useState(false);

  const loadCustomers = async (page = customersMeta?.page || 1) => {
    setLoading(true);
    try {
      const params: string[] = [];

      const result = await apiInstance.get(
        `/customers?q=${searchQuery}&page=${page}${
          params.length ? `&${params.join('&')}` : ``
        }`,
      );
      setHaveSearchQuery(searchQuery !== '');
      if (result.data !== null) {
        const [res] = result.data;
        if (res) {
          setCustomers(res.result as any[]);
          setCustomersMeta(res.meta as IPaginateMeta);
        }
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: t('MainPage.Customers.toasts.loading_customers_error'),
      });
    }
    setLoading(false);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
    return false;
  };

  useEffect(() => {
    navigateHeaderRemove(nexo);
    loadCustomers();
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={<BillingButton />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="4">
                <Box display="flex" flexDirection="column" gap="2">
                  <Box display="flex" gap="1">
                    <Input.Search
                      placeholder={`${t(
                        'MainPage.Customers.Search.query_placeholder',
                      )}`}
                      disabled={loading}
                      name="search-query"
                      onChange={handleChangeSearch}
                      value={searchQuery}
                    />
                    <Button disabled={loading} onClick={() => loadCustomers()}>
                      {t('MainPage.Customers.Search.cta_text')}
                      <Icon color="currentColor" source={<SearchIcon />} />
                    </Button>
                  </Box>
                </Box>

                {loading || customers.length ? (
                  <Table>
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell as="th" width="100px">
                          {t('MainPage.Customers.Table.Head.title1')}
                        </Table.Cell>
                        <Table.Cell as="th">
                          {t('MainPage.Customers.Table.Head.title2')}
                        </Table.Cell>
                        <Table.Cell as="th">
                          {t('MainPage.Customers.Table.Head.title3')}
                        </Table.Cell>
                        <Table.Cell as="th" width="180px">
                          {t('MainPage.Customers.Table.Head.title4')}
                        </Table.Cell>
                        <Table.Cell as="th" width="130px">
                          <></>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {loading ? (
                        <Table.Row>
                          <Table.Cell>
                            <Text.Skeleton />
                          </Table.Cell>
                          <Table.Cell>
                            <Text.Skeleton />
                          </Table.Cell>
                          <Table.Cell>
                            <Text.Skeleton />
                          </Table.Cell>
                          <Table.Cell>
                            <Text.Skeleton />
                          </Table.Cell>
                          <Table.Cell>
                            <Button.Skeleton />
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        customers.map((row) => (
                          <Table.Row key={row.id}>
                            <Table.Cell>#{row.id}</Table.Cell>
                            <Table.Cell>{row.name}</Table.Cell>
                            <Table.Cell>{row.email}</Table.Cell>
                            <Table.Cell>{row.identification || '-'}</Table.Cell>
                            <Table.Cell>
                              <Button
                                appearance="neutral"
                                onClick={() => {
                                  push(`/customer/${row.id!}/orders`);
                                }}
                              >
                                {t(
                                  'MainPage.Customers.Table.Body.select_cta_text',
                                )}
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    </Table.Body>
                  </Table>
                ) : (
                  <EmptyMessage
                    title={t('MainPage.Customers.Search.EmptyMessage.title')}
                    text={`${t(
                      `MainPage.Customers.Search.EmptyMessage.${
                        haveSearchQuery ? 'text_search' : 'text'
                      }`,
                    )}`}
                    icon={<SearchIcon size={32} />}
                    actions={
                      <Link
                        appearance="primary"
                        onClick={() => document.location.reload()}
                      >
                        <Icon color="currentColor" source={<RedoIcon />} />
                        {t('MainPage.Customers.Search.EmptyMessage.cta')}
                      </Link>
                    }
                  />
                )}

                {customersMeta && customersMeta.pages > 1 ? (
                  <Pagination
                    activePage={customersMeta.page || 1}
                    onPageChange={loadCustomers}
                    pageCount={customersMeta.pages || 0}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
